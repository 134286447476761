.default-card {
  border-radius: 8px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.default-card .ant-card-body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.default-card .ant-card-body::-webkit-scrollbar {
  display: none;
}

.default-card .ant-card-head {
  border-bottom: none;
}

.default-card .ant-card-actions {
  border-top: none !important;
}

.default-card .ant-card-actions > li {
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.75) !important;
}
