.dashHeader {
  border-bottom: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  background-color: #fff !important;
  position: fixed;
  top: 0;
  background: #fff !important;
  width: 100%;
  z-index: 100;
  padding-inline-start: 16px;
  padding-right: 0px;
}

.sideDrawer {
  padding-block: 32px;
  padding-inline: 16px;
  height: 100%;
  position: fixed;
  top: 64px;
  left: 0px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  width: 12%;
}

.content {
  min-height: calc(100vh - 64px);
}

.centerContainer {
  padding: 45px !important;
  padding-right: 0px !important;
}

.loginBtn .ant-btn {
  border-radius: 86px !important;
}
.startedButton > span {
  color: black;
}
.startedButton {
  background: none !important;
  border-radius: 85px !important;
  padding: 5px 21px 5px 21px !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: normal;
  border: solid 1px black !important;
}
.startedButton:hover {
  border: none;
}
.loginButton > span {
  font-size: 16px;
  border-radius: 85px;
  text-align: center;
  gap: "9px";
}
.loginButton {
  border-radius: 85px !important;
  /* color: white !important; */
  border: none;
  font-weight: 500 !important;
}

.sitaraLogo {
  cursor: pointer;
  height: 45px;
  width: auto;
}

.menuDropDown ul {
  padding: 12px !important;
}
.menuDropDown {
  position: fixed;
  top: 64px !important;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.profileDropDown ul {
  padding: 12px !important;
  top: 18px;
}

.profileMenu {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.appsDiv {
  gap: 10px;
  cursor: pointer;
  margin: 10px;
  max-width: 15vw;
  align-items: center;
}

.appSelected:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.appSelected {
  border-radius: 8px;
}

.loadingHeaderAnimation {
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: 0;
  transition: width 0.4s ease;
}

.skeletonParagraph {
  width: 100%;
  height: 16px;
  background: #eaecf0;
  margin-bottom: 10px;
}
.scrollDiv {
  width: 18vw;
}
.scrollDiv::-webkit-scrollbar {
  width: 10px;
}
.scrollDiv::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 6px;
}
.scrollDiv::-webkit-scrollbar-track {
  background-color: transparent;
}

.contentStyle {
  background-color: #f3f5f8 !important;
  padding: 96px 3vw 32px 32px;
  height: 100% !important;
  z-index: 50 !important;
}
.contentNoHeaderStyle {
  background-color: #f3f5f8 !important;
  padding: 96px 8px 8px 0px;
  height: 100% !important;
  z-index: 50 !important;
}
.siderNoCollapse {
  background-color: #fff !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 250px !important;
  min-width: 250px !important;
  /* padding-inline: 12px !important; */
  border-right: 1px solid #d0d5dd !important;
}
.siderCollapse {
  background-color: #fff !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 80px !important;
  min-width: 80px !important;
  border-right: 1px solid #d0d5dd !important;
}
.siderNoCollapse div[class="ant-layout-sider-trigger"] {
  width: 250px !important;
}

.headerStyle {
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #d0d5dd;
  z-index: 100;
}
.headerWithSideAndCollapse {
  width: calc(100vw - 80px - 3vw);
  left: calc(80px);
  padding: 0px;
}
.headerWithSideAndNoCollapse {
  width: calc(100vw - 250px);
  left: calc(250px);
  padding: 0px;
}

.headerStylesWithoutSide {
  width: 100vw;
  left: 0;
  padding-inline: 32px 3vw;
}
.footerStyle {
  position: fixed;
  background-color: #fbf9ff;
  border-top: 1px solid #d0d5dd;
  z-index: 100;
  left: 0;
  bottom: 0;
}
.footerStyleWithCollapsedSide {
  width: calc(100vw - 80px);
  left: calc(80px + 32px);
  padding-inline: 32px 3vw;
}
.footerStyleWithNOCollapsedSide {
  width: calc(100vw - 250px - 3vw - 32px);
  left: calc(250px + 32px);
  padding-inline: 32px 3vw;
}
.footerStyleWithOutSide {
  width: 100vw;
  left: 0;
  bottom: 0;
  padding-inline: 32px 3vw;
}
.profileMenu {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.settingsdropDown {
  width: 300px;
}

.contentPushWithSideandCollapse {
  min-height: 100vh;
  margin-left: 80px;
  overflow-y: auto;
}
.contentPushWithSideandNoCollapse {
  min-height: 100vh;
  margin-left: 250px;
  overflow-y: auto;
}
.contentPushWithoutSide {
  min-height: 100vh;
  margin-left: 0;
  overflow-y: auto;
}
@media screen and (max-width: 820px) {
  .contentNoHeaderStyle {
    background-color: #f3f5f8 !important;
    padding: 140px 0px 8px 0px;
    height: 100% !important;
    z-index: 50 !important;
  }
}
