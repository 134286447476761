.all-img-container {
  padding: 0px 20px;
}

.img-container {
  /* overflow-x: scroll;
    overflow-y: hidden; */
  /* white-space: nowrap; */
  padding: 10px;
}

.img-card {
  width: 200px;
  transition: transform 0.3s ease;
}

.img-card:hover {
  transform: scale(1.1);
}

/* .inv-name {
    color: "#7244d5";
    cursor: "pointer";
    font-weight: bold;
    font-size: "16px";
} */

.meta-title {
  display: flex;
  justify-content: center;
  color: darkgray;
}

.inv-slider {
  height: auto;
  width: auto;
}

.inv-slider-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 400px; */
  overflow: hidden;
}

.inv-slider-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.inv-slider-filename {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lightter;
  font-size: 14px;
  color: darkgray;
  margin-bottom: 10px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.custom-prev {
  left: -120px;
}

.custom-next {
  right: -120px;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
