textarea.mdInput,
span.mdInput,
input.mdInput {
  border-radius: 8px;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: auto;
  height: auto;
  resize: none;
}

input.mdInput:focus {
  color: #101828;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
input.mdInput::placeholder {
  color: #8b93a1;
}
textarea.mdInput:focus {
  color: #101828;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
textarea.mdInput::placeholder {
  color: #8b93a1;
}
span.mdInput:placeholder-shown {
  color: #8b93a1 !important;
}
span.mdInput input::placeholder {
  color: #8b93a1 !important;
}

span.mdInput:focus-within {
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
span.mdAddonInput input {
  border-radius: 8px;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: auto;
  height: auto;
}

div.mdNumberInput div[class="ant-input-number-input-wrap"] input {
  border-radius: 8px;
  display: flex;
  padding: 6px 14px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
  /* width: auto; */
  height: auto;
}

div.mdNumberInput:focus-within {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
div.mdNumberInput:hover {
  border: 1px solid #d0d5dd !important;
}
div.smNumberInput div[class="ant-input-number-input-wrap"] input {
  border-radius: 8px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
  width: auto;
  height: auto;
}
div.smNumberInput:hover {
  border: 1px solid #d0d5dd !important;
}
div.smNumberInput:focus-within {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

/* ==================================================== */

span.smInput,
input.smInput {
  border-radius: 8px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: auto;
  height: auto;
}

input.smInput:focus {
  color: #101828;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
input.smInput::placeholder {
  color: #8b93a1;
}
span.smInput:placeholder-shown {
  color: #8b93a1 !important;
}

span.smInput:focus-within {
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
div.mdSelect div[class="ant-select-selector"] {
  border-radius: 8px;
  display: flex;
  padding: 3px 14px !important;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: auto;
  height: auto !important;
}
div.mdSelect
  div[class="ant-select-selector"]
  span[class="ant-select-selection-item"] {
  line-height: 24px;
}
div.mdSelect
  div[class="ant-select-selector"]
  span[class="ant-select-selection-search"]
  input[class="ant-select-selection-search-input"] {
  height: 100% !important;
}
div.mdSelect {
  height: 100% !important;
}

div.mdSelect:focus-within {
  border-radius: 8px;
  border: 1px solid #d0d5dd !important;
  height: 100%;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
div.mdSelect div[class="ant-select-selector"]:hover {
  border: 1px solid #d0d5dd !important;
}
div.mdSelect div[class="ant-select-selector"]:focus-within {
  border: 1px solid #d0d5dd !important;
}
div.smSelect div[class="ant-select-selector"] {
  border-radius: 8px;
  display: flex;
  padding: 5px 12px !important;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: auto;
  height: auto !important;
}
div.smSelect
  div[class="ant-select-selector"]
  span[class="ant-select-selection-item"] {
  line-height: 24px;
}
div.smSelect
  div[class="ant-select-selector"]
  span[class="ant-select-selection-search"]
  input[class="ant-select-selection-search-input"] {
  height: 24px;
}
div.smSelect {
  height: 100% !important;
}
div.smSelect:focus-within {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
div.smSelect div[class="ant-select-selector"]:hover {
  border: 1px solid #d0d5dd !important;
}
div.smSelect div[class="ant-select-selector"]:focus-within {
  border: 1px solid #d0d5dd !important;
}

div.mdDateInput {
  border-radius: 8px;
  display: flex;
  padding: 6px 14px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: fit-content;
  height: auto;
}
div.mdDateInput:focus-within {
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  border: 1px solid #d0d5dd !important;
  font-size: 16px;
}
div.mdDateInput:hover {
  border: 1px solid #d0d5dd !important;
}

div.mdDateInput div[class="ant-picker-input-placeholder"] input {
  font-size: 16px !important;
}
div.mdDateInput div[class="ant-picker-input"] input {
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

div.smDateInput {
  border-radius: 8px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: fit-content;
  height: auto;
}
div.smDateInput:focus-within {
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  border: 1px solid #d0d5dd !important;
  font-size: 16px;
}

div.smDateInput:hover {
  border: 1px solid #d0d5dd !important;
}
div.smDateInput div[class="ant-picker-input"] input:hover {
  font-size: 16px !important;
}
div.smDateInput div[class="ant-picker-input"] input {
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.mdDropDown {
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.tagInput {
  border: 0px !important;
  border-radius: 8px;
  width: fit-content !important;
  font-size: 16px;
  font-style: normal;
  outline: 0px !important;
}
.tagInput:focus-visible {
  border: 0px !important;
  font-size: 16px;
  font-style: normal;
}
.tagInput::placeholder {
  color: #8b93a1 !important;
}
.tapInputContainer {
  width: 100%;
  margin-top: 32px;
  border-radius: 8px;
  padding: 14px 10px;
  border: 1px solid #d0d5dd;
  font-size: 16px;
  font-style: normal;
  min-height: 50px !important;
}
.taginputTag {
  font-size: 14px;
  border-radius: 20px;
  height: fit-content;
  padding: 2px 4px 2px 9px;
}
@media screen and (max-width: 1600px) {
  textarea.mdInput,
  span.mdInput,
  input.mdInput {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }
  span.smInput,
  input.smInput {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  div.mdSelect div[class="ant-select-selector"] {
    padding: 3px 12px !important;
    font-size: 14px;
    line-height: 20px;
  }
  div.mdSelect
    div[class="ant-select-selector"]
    span[class="ant-select-selection-item"] {
    line-height: 20px;
  }

  div.mdNumberInput div[class="ant-input-number-input-wrap"] input {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  span.mdAddonInput input {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  div.mdDateInput {
    display: flex;
    padding: 6px 14px;
    font-size: 14px;
    line-height: 20px;
  }
  div.mdDateInput:focus-within {
    box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    border: 1px solid #d0d5dd !important;
    font-size: 14px;
  }
}
.discountInput span span {
  padding: 0px !important;
}
