.h1 {
  font-size: 72px;
  line-height: 90px;
  font-weight: 600;
  letter-spacing: -0.09rem;
}
.h2 {
  font-size: 60px;
  line-height: 72px;
  font-weight: 600;
  letter-spacing: -0.09rem;
}

.h3 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: -0.09rem;
}
.h4 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: -0.09rem;
}

.h5 {
  font-size: 30px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: 0;
}
.h6 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0;
}

.body1 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0;
}
.body2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.body3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
}

.subtext1 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0;
}
.subtext2 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0;
}

@media screen and (max-width: 576px) {
  .h5 {
    font-size: 24px;
    line-height: 32px;
  }
}
