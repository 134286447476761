.patient-list {
  max-height: auto; /* Default height for tablets */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Media query for larger screens */


/* .ant-list-header .list-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff !important;
} */
