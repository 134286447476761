.orgModal div[class="ant-modal-content"] {
  padding: 0px;
}
.scrollDiv::-webkit-scrollbar {
  width: 10px;
}
.scrollDiv::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 6px;
}
.scrollDiv::-webkit-scrollbar-track {
  background-color: transparent;
}
