.ip-forms {
  padding: 40px;
}
.ip-forms-review {
  height: 90%;
  overflow: auto;
  background: #f6f5ff;
}
.descriptions-custom-column-width .ant-descriptions-item-label {
  width: 40%;
}

.descriptions-custom-column-width .ant-descriptions-item-content {
  width: 60%;
}

.icon-text-segment.ant-space-gap-row-small {
  row-gap: 0px !important;
}

.care-item-card-stop-styles,
.care-item-card-stop-styles .ant-card-actions {
  background-color: rgba(205, 155, 155, 0.2) !important;
}
.ant-card-actions {
  padding: 0% !important;
}
.custom-badge-size.ant-badge.ant-badge-status .ant-badge-status-dot {
  height: 9px !important;
  width: 9px !important;
}

.physioreview-drawer .ant-drawer-body {
  padding: 0px;
}
.custom-error .ant-form-item-explain-error {
  margin-top: 8px;
}

.wrap-text td {
  white-space: normal !important;
  word-break: break-word;
}

.no-scroll ::-webkit-scrollbar {
  display: none;
}
