label.smCheck span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
label.mdCheck span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
}

label.mdCheck span span[class="ant-checkbox-inner"] {
  width: 20px;
  height: 20px;
}
label.smRadio span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
label.mdRadio span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
}
