.physio-scales .scale-footer {
  position: fixed;
  right: 0;
  top: 600px;
  background-color: transparent;
  text-align: center;
  padding: 8px 16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.physio-scales .scale-footer2 {
  position: fixed;
  right: 0;
  top: 600px;
  background-color: transparent;
  text-align: center;
  padding: 8px 16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.physio-scales .scale-footer .ant-btn {
  min-width: 95px;
}
@media (max-width: 1700px) {
  .physio-scales .scale-footer2 {
    flex-direction: column;
    align-items: center;
  }
}
.physio-scales .scales-comments-formitem {
  position: fixed;
  width: 220px;
  right: 0px;
  top: 500px;
}

.physio-scales .score-counter {
  height: 110px;
  width: 220px;
  background-color: #7f56d9;
  color: white;
  position: fixed;
  right: 0px;
  top: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.physio-scales .score-counter.ant-typography {
  font-family: Noto Sans;
  font-weight: 600;
  font-size: 36px;
  line-height: 24px;
  text-align: center;
  z-index: 10;
}

.physio-scales .score-counter.ant-typography span {
  font-family: Noto Sans;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
}

.physio-scales .score-counter.line {
  width: 60px;
  border: 4;
  border-color: #fff;
}

.physio-scales .score-string {
  width: 140px;
  font-size: 10px;
  padding: 5px;
  background-color: #ffe178;
  color: #444444;
  position: absolute;
  top: -20%;
  left: 10%;
  transform: translateX(-20%);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.physio-scales .scale-info {
  /* position: absolute; */
  /* top: 10px;
  left: 5px; */
  background-color: rgb(218, 142, 2);
  color: #fff;
}

.cardless-scale {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  background: linear-gradient(
    151deg,
    rgb(52 4 113) 0%,
    rgb(74 56 56) 35%,
    rgb(49, 3, 52) 100%
  );
  min-height: 77svh;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.cardless-scale.no-bottom .ant-card-head {
  border-bottom: none;
}

.cardless-scale.no-bottom.barthel-index {
  align-items: flex-start;
}

/* Pain Scale type components */
.physio-scales .radio-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.physio-scales .visual-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.physio-scales .visual-image {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.physio-scales .visual-label {
  font-weight: bold;
  font-size: 14px;
}

/* To distiguish checked radio buttons */
.physio-scales .radio-group .ant-radio-button-wrapper-checked {
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
  color: #fff;
}

.physio-scales .radio-group .ant-radio-button-wrapper {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
}

.physio-scales .radio-group .ant-radio-button-wrapper:not(:last-child) {
  margin-right: 8px;
}

.physio-scales .radio-group .ant-radio-button-wrapper:hover {
  border-color: #7f56d9;
}

.physio-scales .ant-slider-mark {
  /* width: 100px; */
  margin-top: 10px;
}

.physio-scales .ant-slider-mark-text {
  font-size: 10px;
  font-weight: bold;
  display: block;
  overflow: hidden;
  color: #fff;
  font-family: "";
}

.physio-scales .ant-slider.ant-slider-disabled .ant-slider-rail {
  background-color: #fff !important;
  height: 6px;
}

.physio-scales .ant-slider.ant-slider-disabled .ant-slider-dot {
  border-color: #7f56d9;
  height: 12px;
  width: 12px;
}

.physio-scales .ant-slider.ant-slider-disabled .ant-slider-dot-active {
  background-color: #7f56d9;
}

.physio-scales .ant-slider.ant-slider-disabled .ant-slider-handle::before {
  background-color: #7f56d9;
  filter: blur(4px);
}

.physio-scales .ant-slider.ant-slider-disabled .ant-slider-handle::after {
  background-color: #7f56d9;
}

.physio-scales .ant-slider.ant-slider-disabled .ant-slider-track {
  background-color: #7f56d9 !important;
  height: 6px;
}

.physio-scales .barthel-index-buttons .ant-radio-button-wrapper-checked {
  background-color: #7f56d9;
  color: #fff;
  z-index: 0;
}

.physio-scales .selection-slider .ant-slider-mark-text {
  font-size: 14px;
  font-family: Noto Sans;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.physio-scales .selection-slider .ant-slider .ant-slider-mark-text-active {
  color: #fff !important;
}

.physio-scales .selection-slider .ant-slider-rail {
  height: 6px;
  background-color: #d9dadb;
}

.physio-scales .selection-slider.stepper {
  top: 40px;
  position: absolute;
}

.physio-scales .selection-slider.stepper .ant-slider-rail {
  height: 6px;
  background-color: #fff;
}

.physio-scales .selection-slider .ant-slider-dot {
  height: 12px;
  width: 12px;
  top: -2px;
}

.physio-scales .selection-slider.stepper .ant-slider-dot {
  height: 12px;
  width: 12px;
  top: -2px;
  border-color: #7f56d9;
}

.physio-scales .selection-slider .ant-slider-dot-active {
  background-color: #eaecf0;
}

.physio-scales .selection-slider .ant-slider-track {
  background-color: #7f56d9;
  height: 6px;
}

.physio-scales .selection-slider .ant-slider-handle:focus::after {
  width: 15px;
  height: 15px;
}

.physio-scales .selection-slider .ant-slider-handle::before {
  height: 12px;
  width: 12px;
  /* box-shadow: 1px 0 1px 1px #7f56d9; */
}

.physio-scales .selection-slider .ant-slider-handle::after {
  height: 12px;
  width: 12px;
  top: 0;
  left: -2px;
  border-radius: 0;
  box-shadow: 0 0 0 2px #c8aef2;
}

.physio-scales .selection-slider .ant-slider-handle.ant-tooltip-open::after {
  height: 12px;
  width: 12px;
}

.physio-scales .selection-slider .ant-slider-handle.ant-tooltip-open::before {
  height: 18px;
  width: 18px;
}

.physio-scales .ant-typography {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: white !important;
  /* text-align: center; */
  /* font-family: Noto Sans; */
}

.physio-scales.initial .ant-typography {
  color: #101828 !important;
}

.physio-scales .like-btn.ant-radio-button-wrapper-checked {
  transform: scale(1.05);
  background: rgb(75, 163, 75);
}

.physio-scales .like-btn .ant-radio-button-checked + span .anticon svg {
  color: white;
}

.physio-scales .like-btn:not(.ant-radio-button-wrapper-checked) .anticon svg {
  color: #101828;
}

.physio-scales .dislike-btn.ant-radio-button-wrapper-checked {
  transform: scale(1.05);
  background: rgb(174, 74, 74);
}

.physio-scales .dislike-btn .ant-radio-button-checked + span .anticon svg {
  color: white;
}

.physio-scales
  .dislike-btn:not(.ant-radio-button-wrapper-checked)
  .anticon
  svg {
  color: #101828;
}

.physio-scales .ant-tabs-nav {
  margin-bottom: 0px;
}

.physio-scales .ant-tabs-tab-active {
  background: rgb(52 4 113) !important;
}

.physio-scales
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab-active {
  border-bottom-color: rgb(52 4 113) !important ;
}
.physio-scales .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  border: none !important;
}

.physio-scales .ant-card,
.physio-scales .ant-list,
.physio-scales .ant-table,
.physio-scales .ant-table-wrapper .ant-table-thead > tr > th {
  background: rgb(16, 24, 40, 0.8);
  color: white !important;
}

.physio-scales .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: #101828 !important;
}
.physio-scales .ant-form-item .ant-form-item-label > label {
  color: white;
}

.physio-scales .textarea.ant-input {
  background: #737676 !important;
  /* color: white; */
}

.physio-scales .ant-radio-wrapper,
.physio-scales .ant-input-number-group .ant-input-number-group-addon {
  color: white !important;
}
/* .physio-scales .ant-input-outlined:hover {
  color: #101828;
} */

.physio-scales .ant-checkbox + span {
  color: white;
}

.physio-scales .ant-select .ant-select-arrow {
  color: black;
}

.physio-scales.initial .selection-slider .ant-slider-mark-text {
  color: black;
}

.physio-scales.initial .ant-form-item .ant-form-item-label > label {
  color: black;
}

.physio-scales .cardless-scale .naming-img {
  /* width: 100%; */
  width: 450px;
  height: auto;
  border-radius: 16;
  margin-bottom: 16;
  background: #fff;
}

.physio-scales.initial .ant-radio-wrapper,
.physio-scales.initial .ant-input-number-group .ant-input-number-group-addon {
  color: black !important;
}

.physio-scales .cardless-scale .division-header {
  margin: 8px 0px;
  color: white !important;
  border-color: #ececec !important;
}

.physio-scales .cardless-scale .moca-card {
  max-width: 1400px;
  /* left: -50px; */
}

@media screen and (max-width: 1300px) {
  .cardless-scale {
    align-items: flex-start;
  }
  /* .physio-scales .selection-slider.stepper {
    text-align: center;
  } */
  .physio-scales .cardless-scale .naming-img {
    max-width: 280px;
  }

  .physio-scales .cardless-scale .moca-card {
    max-width: 900px;
    left: 0;
  }

  .physio-scales.no-scroll ::-webkit-scrollbar {
    display: none;
  }
}
