.op-forms .scale-info {
  background-color: rgb(218, 142, 2);
  color: #fff;
}

.op-forms .no-bg .ant-select-selector {
  background: transparent;
}

.op-forms .history-slider .ant-slider-mark {
  top: 20px;
}

.op-forms .history-slider .ant-slider-mark-text-active {
  background-color: #eef4ff;
}

.op-forms .history-slider .ant-slider-rail {
  width: 6px;
  background-color: #d5d9eb;
}

.op-forms .history-slider .ant-slider-track {
  width: 6px;
  background-color: #3538cd;
}

.op-forms .history-slider .ant-slider-dot {
  width: 10px;
  height: 10px;
}

.op-forms .history-slider .ant-slider-dot-active {
  width: 10px;
  height: 10px;
  border-color: #3538cd;
  background-color: #3538cd;
}

.op-forms .history-slider .ant-slider-handle::after {
  width: 12px;
  height: 12px;
  /* border-radius: 10px 10px 50px 0; */
  box-shadow: 0 0 0 2px #3538cd;
}

.op-forms .history-card .ant-card-head {
  background-color: #e8e3ff;
}

.op-forms .history-card .ant-card-body {
  max-height: 70dvh;
  overflow-y: auto;
  padding: 15px;
}

@media (max-width: 1300px) {
  .history-cards {
    width: 200;
  }
}
