.parentContainer {
  padding-top: 45px;
  width: 100%;
  min-height: calc(100vh - 45px);
  /* background-image: url("/images/auth/ellipse.svg"); */
  background-position: 94vw -20vh;
  background-size: auto 20vw;
  background-repeat: no-repeat;
}
.webHidden {
  display: none;
}
.mobileHidden {
  display: block;
}

.orgButton:hover {
  background: #f9f5ff;
}
.orgErrorButton {
  background: #f9f5ff;
  /* border-bottom: 0px !important; */
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.orgButtonError {
  background: #fef3f2;
  padding: 8px 16px;
  border: 1px solid #d0d5dd;
  border-top: 0px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.orgButtonErrorText {
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  color: #475467;
  line-height: 18px;
}
@media screen and (max-width: 992px) {
  .webHidden {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .mobileHidden {
    display: none;
  }
}
