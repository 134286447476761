body {
  margin: 0px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.flex-around {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.wFull {
  width: 100%;
}

.hyperlink {
  color: #007aff !important;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px !important;
  cursor: pointer;
}

.hyperlink:hover {
  text-decoration: underline;
}

/* ***************antd default styles chnages globally*********************** */

.mdAddonInput
  .ant-input-wrapper.ant-input-group
  .ant-input-group-addon
  .ant-select
  .ant-select-selector {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 0px;
  width: auto;
  height: auto;
}

.ant-menu-submenu-title {
  color: #101828 !important;
}
.medicine-search .ant-form-item-explain-error {
  margin-top: 8px;
  color: #000000; /* Adjust this to control space between error message and input */
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px;
}

/* *********************************** */
.ant-form {
  /* padding: 40px; */
  /* max-width: 900px; */
}

.subsection-card {
  padding: 8px;
  margin-left: 24px;
  border-radius: 2px;
  border-left: 2px solid #efe5f8;
  margin-bottom: 8px;
}

.checkbox-group {
  display: "inline-block";
  margin-right: 10px;
}

.ant-form-item-control {
  /* margin-left: 12px; */
}

.ant-select {
  max-width: 400px !important;
}

.width-200 {
  width: 200px;
}

/* .segment-wrap .ant-segmented-item-label {
  padding: 14px 10px !important;
} */
.bed-upload .ant-upload-drag {
  background: none !important;
  border: none !important;
}

.ant-collapse {
  border: none !important;
  background-color: transparent !important;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.segment-wrap .ant-segmented-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-table-container .ant-table-header th.ant-table-cell {
  font-weight: lighter;
  font-size: 12px;
}

.ant-table-container .ant-table-content .ant-table-thead .ant-table-cell {
  font-weight: lighter;
  font-size: 12px;
}

.facility-disable .ant-input-disabled {
  color: #000000 !important;
  background-color: #fff;
  border: none !important;
}

.custom-collapse .ant-collapse-content-box {
  border-top: none !important;
}
.custom-dropdown .ant-select-item-option-content {
  white-space: normal;
  word-break: break-word;
}

.ant-layout .ant-layout-sider-trigger {
  background-color: #1d2939;
}

.patientCard .ant-card-body {
  padding: 12px !important;
  border-radius: 0 0 8px 8px;
}

.patientTimeLine .ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 10px !important;
  font-size: 14px;
  list-style: none;
}

.ant-menu-light {
  border-bottom: none !important;
}

.ant-menu-horizontal {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000000 !important;
}

.slick-next {
  right: 0 !important;
  /* display: none !important; */
}

.slick-prev {
  left: 45 !important;
  /* display: none !important; */
}

.ant-steps-item {
  white-space: normal !important;
}

.patientMenu .ant-menu-item {
  padding-inline: 4px !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.patientMenu .ant-menu-item:hover {
  transform: scale(1.2);
}

.ant-menu-item-selected::after {
  border-bottom: 0px !important;
}

.ant-menu-item:hover::after {
  border-bottom: 0px !important;
}

.ant-menu-item::after {
  border-bottom: 0px !important;
}

.ant-descriptions-title {
  text-align: left;
}

.pdfContainer {
  height: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  width: 100%;
  padding: 10px;
}

/* .pdfContainer:hover {
  background-color: red;
} */
/* .nurse-list .ant-list-item {
  max-width: 60% !important;
} */
/* .care-item-card .ant-card-head {
  background: #f2f0ff !important;
} */
.ant-table-header {
  background: #f2f0ff;
}

.ant-input-number-disabled {
  color: #000000 !important;
  background-color: transparent !important;
}

.ant-input-disabled {
  color: #000000 !important;
  background-color: transparent !important;
}

/* .tag:hover {
  background-color: green !important;
} */
.bed-tag-management {
  flex: none !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-table-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
}
.initial-insp-desc .ant-descriptions-header {
  margin-bottom: 5px !important;
}
.initial-insp-desc .ant-descriptions-item-label {
  width: 200px !important;
}
.initial-insp-desc {
  flex: 1;
  padding: 10px;
  text-align: left;
  margin-bottom: 20px !important;
  text-align: left !important;
}

.pointer {
  cursor: pointer;
}

.tag-completed {
  background-color: #ecfdf3;
  color: #067647;
  border: 1px solid #abefc6;
  border-radius: 5px;
}

.tag-pending {
  background-color: #fef3f2;
  color: #b42318;
  border: 1px solid #fecdca;
  border-radius: 5px;
}

.card-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ant-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.bed-row {
  background-color: #fbecec;
}
.btn-scale {
  font-weight: 500;
  transition: transform 0.2s ease;
}

.btn-scale:hover {
  transform: scale(1.05);
}
/* .right-input .ant-input-number-input-wrap {
  padding-right: 18px;
} */
.amount-input .ant-input-number-input-wrap {
  padding-right: 40px !important;
}
.right-input .ant-input-number-input-wrap > input.ant-input-number-input {
  text-align: right;
}
.amount-input .ant-input-number-input-wrap > input.ant-input-number-input {
  text-align: right;
}

.iv-fluid-card {
  box-shadow: none;
}
