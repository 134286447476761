/* .ant-descriptions-title {
    font-size: 20px; 
    font-weight: bold; 
    display: flex;
    align-items: center; 
    border-top: #555;
  }
  
  .ant-descriptions-item-label {
    font-weight: bold; 
  }
  
  .ant-descriptions-item-content {
    color: #555; 
  }
  
  .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #f5f5f5; 
  }
   */

/* styles.css */
.custom-descriptions .ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f5f5f5;
}

.custom-descriptions .ant-descriptions-item-label,
.custom-descriptions .ant-descriptions-item-content {
  padding: 8px;
}

.custom-descriptions .ant-descriptions-view {
  border: none;
  margin: 0;
}

/* Adjust the border of the descriptions items to match the card border */
.custom-descriptions .ant-descriptions-item {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.custom-descriptions .ant-descriptions-item:nth-child(even) {
  border-left: 0;
}

.custom-descriptions .ant-descriptions-item:last-child {
  border-bottom: 0;
}

.ant-card-body {
  padding: 0;
}

.ant-card-head {
  border-bottom: 1px solid #d9d9d9;
}
.summaries {
  display: flex;
  justify-content: space-between;
  width: "100%";
}
.summaries .ant-tag {
  margin-top: 5px !important;
  margin-bottom: 4px !important;
}

.summaries .ant-descriptions-item-label,
.summaries .ant-descriptions-item-content {
  background-color: #ffffff !important;
}

.summaries .insp-card {
  flex: 1;
  margin: 0 8px;
  background-color: #f3f5f8;
  border-radius: 8px;
  padding: 1rem;
}
