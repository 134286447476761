.gallery-img-card {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-img-card:hover {
  transition: transform 0.5s ease;
  transform: scale(1.05);
}

.gallery-meta-title {
  padding-left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
  font-size: 12px !important;
}

.gallery-meta-desc {
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: 500;
  font-size: smaller;
}

/* .gallery-card-container {
  overflow-y: auto;
  height: calc(100vh - 260px);
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

/* .gallery-card-container::-webkit-scrollbar {
  display: none;
} */
.patient-gallery {
  overflow-y: hidden !important; 
  height: 84vh;
}
.patient-gallery::-webkit-scrollbar {
  display: none !important;
}
.gallery-img-container {
  /* overflow-x: scroll; */
  /* overflow-y: hidden;  */
  /* white-space: nowrap; */
  background-color: white;
  /* border: 1px solid rgb(217, 217, 217); */
  /* border-radius: 10px; */
  padding: 20px;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 40px;
}
