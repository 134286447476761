.custom-button {
  background-color: transparent;
  color: #1890ff;
}

.custom-button:hover {
  background-color: violet;
  color: white;
}

/* .inv-upload-file {
  transition: transform 0.3s ease;
}

.inv-upload-file:hover {
  transform: scale(1.1);
} */

.inv-upload-del-btn {
  color: black;
}

/* .inv-upload-del-btn :hover {
  color: red;
} */

.inv-btn {
  font-weight: 500 !important;
  transition: transform 0.2s ease;
}

.inv-btn:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}
.five-columns {
}
.masonry-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Horizontal gap */
  transition: transform 0.2s ease;
}
.masonry-item:hover {
  transform: scale(1.05);
  /* background-color: #b3f0ff; */
}

.ant-table-thead > tr > th {
  font-size: 14px !important;
  /* font-weight: 600 !important;  */
  color: #111 !important;
}
