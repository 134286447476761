button.lgBtn {
  display: inline-flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: auto;
}
button.lgBtn[class="ant-btn-default"]:hover {
  border-color: #d0d5dd !important;
  color: #344054 !important;
}

button.mdBtn {
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: auto;
}
button.mdBtn[class="ant-btn-default"]:hover {
  border-color: #d0d5dd !important;
  color: #344054 !important;
}
button.textBtn {
  box-shadow: none;
  padding: 0px;
}
button.textBtn:hover {
  background-color: transparent !important;
}

button.socialBtn {
  display: flex !important;
  padding: 10px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: auto;
  align-self: center;
}
button.socialBtn span {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: auto;
}

button.facebook {
  background-color: #1877f2;
  color: #fff;
  border: 0px;
}

button.facebook:hover {
  color: #fff !important;
  border: 0px;
}
button.google {
  background-color: #fff;
}
button.google:hover {
  color: #344054 !important;
  border-color: #344054 !important;
}
@media screen and (max-width: 1600px) {
  button.mdBtn {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
  }
  button.lgBtn {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px; /* 150% */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: auto;
  }
  button.socialBtn {
    padding: 8px 16px;
  }
}
