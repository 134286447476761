.dashboard-col-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nurse-careitems-btn {
  min-width: 30px;
  display: inline-block;
  text-align: center;
}

.dashboard-card-text {
  color: "red";
  font-size: 12px;
}
/* #323232 */
