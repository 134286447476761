.loginButton {
  width: fit-content;
  box-shadow: none !important;
  padding: 12px 22px !important;
  height: auto !important;
}
.solutionCard {
  border-radius: 16px;
  background-color: linear-gradient(45deg, #a6c0fe 0%, #ffeaf6 100%);
  background: linear-gradient(45deg, #a6c0fe 0%, #ffeaf6 100%);
  width: 70%;
  padding-top: 24px;
  padding-left: 58px !important;
}
.apps {
  border-radius: 8px;
  background: #f9fafb;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
}
div.mdNumberInput div[class="ant-input-number-input-wrap"] input {
  width: 100px !important;
  border-radius: 8px;
  display: flex;
  padding: 4px 14px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
div.mdNumberInput:focus-within {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.apps:hover {
  scale: 1.01;
}
.creditsCard div[class="ant-card-body"] {
  padding: 20px 16px !important;
}

.appCard {
  /* border: 1px solid #eaecf0; */
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1); */
  /* box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04); */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.appCard:hover {
  scale: 1.01;
}
.appCard div[class="ant-card-body"] {
  padding-top: 10px;
}
.appCard div[class="ant-card-head"] {
  padding-top: 20px;
  border-bottom: 0px;
}
.pricingCard {
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #fff;

  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}
.noAppsCard {
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #fff;

  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}
.noAppsCard div[class="ant-card-body"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.appPriceCard {
  /* width: 80%; */
  margin: auto;
  border-radius: 16px !important;
  border: 1px solid #eaecf0;
  background: #fff;
  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}
.creditBalanceCard {
  display: flex;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: linear-gradient(
    0deg,
    #19a9be -55.4%,
    #1aa4bd -43.55%,
    #2097bb -31.71%,
    #2982b8 -18.38%,
    #3665b5 -5.05%,
    #463fb0 6.8%,
    #5719ab 18.65%,
    #1d126b 92.69%
  );
}

.recomendedCard {
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  align-self: stretch;
}

.priceStatistic
  div[class="ant-statistic-content"]
  span[class="ant-statistic-content-value"]
  span[class="ant-statistic-content-value-decimal"] {
  font-size: 24px;
}
.businessPopupHeader {
  height: 120px;
  background: linear-gradient(to bottom, #a5c0ee, #fbc5ec 100%);
  margin-bottom: 80px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
}
.buttonFrame {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.businessBtn {
  padding: 12px;
  gap: 6px;
  border: 1px solid #eaecf0;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.businessBtn:hover {
  background-color: #f9f5ff;
  border-radius: 8px;
}
.skeletonParagraph {
  width: 100%;
  height: 16px;
  background: #eaecf0;
  margin-bottom: 10px;
}

.popOverBody {
  width: 18vw;
  padding: 24px;
}

.sideMenuImg {
  height: 20px;
  width: auto;
}

@media screen and (max-width: 767px) {
  .smHidden {
    display: none;
  }
  .popOverBody {
    width: 50vw;
  }
  .sideMenuImg {
    height: 40px;
    width: auto;
  }
}

@media screen and (max-width: 576px) {
  .smHidden {
    display: block;
  }
  .popOverBody {
    width: 70vw;
  }
  .sideMenuImg {
    height: 20px;
    width: auto;
  }
}
.test div[class="ant-modal-content"] div[class="ant-modal-body"] {
  height: 70vh;
  overflow: auto;
  /* display: flex; */
  align-items: center;
}
.investigation div[class="ant-modal-content"] div[class="ant-modal-body"] {
  height: 80vh;
  overflow-x: auto;
  align-items: center;
}
.inputDisable div[class="ant-input-disabled"] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d0d5dd;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  border: none;
}
