.container {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 0px !important;
}

span.smGreenDotTag {
  display: inline-flex;
  padding: 2px 8px;
  padding-inline-start: 0px;
  align-items: center;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
span.smGreenDotTag::before {
  color: #17b26a;
  content: "\00B7";
  font-size: 70px;
  width: 6px;
  height: 6px;
  margin-right: 20px;
  margin-bottom: 6px;
  font-family: "Supreme"; /* Unicode character for a middle dot */
  /* Adjust the spacing between the dot and the text */
}

span.smRedDotTag {
  display: inline-flex;
  padding: 2px 8px;
  padding-inline-start: 0px;
  align-items: center;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
span.smRedDotTag::before {
  color: #d92d20;
  content: "\00B7";
  font-size: 70px;
  width: 6px;
  height: 6px;
  margin-right: 20px;
  margin-bottom: 6px;
  font-family: "Supreme"; /* Unicode character for a middle dot */
  /* Adjust the spacing between the dot and the text */
}

div.appCard div[class="ant-card-body"] {
  padding: 0px !important;
}
div.appCard div[class="ant-card-head"] {
  border: 0px !important;
  padding: 24px;
}
.profileBanner {
  /* background: rgb(2, 0, 36); */
  height: 130px;
  width: 100%;
  background: #c1acec;
  margin-bottom: 0px;
  z-index: 50;
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.profileAvatar {
  position: absolute;
  top: 90px;
  left: 32px;
  height: 130px;
  width: 130px;
  border-radius: 100%;
  outline: 4px solid #fff;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
}
.profilePicOutline {
  outline: 1px solid #101828;
  opacity: 0.2;
  height: 126px;
  width: 126px;
  border-radius: 100%;
  position: absolute;
}
.profilePicEdit {
  /* position: absolute;
    top: 120px;
    left: 130px; */
  height: 16px;
  width: auto;
}

.profileSection {
  padding-block: 20px;
  /* border-right: 1px solid #eaecf0; */
}

.emailTags {
  padding: 4px 6px 4px 10px;
  border-radius: 8px;
  border: "2px solid #E9D7FE";
  color: "red";
}
.centerModalFooter
  div[class="ant-modal-content"]
  div[class="ant-modal-footer"] {
  text-align: center;
}
.disabledlink {
  background-color: #fff;
}
.tableHeader {
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.copyLink {
  border-radius: 8px;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  width: auto;
  height: auto;
  resize: none;
}

.personalDetailsCard div[class="ant-card-body"] {
  padding: 0px;
}
