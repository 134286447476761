span.smTag {
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  /* border: 1px solid #d0d5dd; */
  /* background: #fff; */
  /* color: #344054; */
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

span.smTag span[class="ant-tag-close-icon"] {
  display: flex;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  /*  background: #f2f4f7;
  color: #344054; */
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
span.mdTag {
  display: inline-flex;
  padding: 3px 9px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  /* border: 1px solid #d0d5dd;
  background: #fff;
  color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
span.mdTag span[class="ant-tag-close-icon"] {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  /* background: #f2f4f7;
  color: #344054; */
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
span.lgTag {
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  /* border: 1px solid #d0d5dd;
  background: #fff;
  color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
span.lgTag span[class="ant-tag-close-icon"] {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  /* background: #f2f4f7;
  color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.test {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
span.smRndTag {
  display: inline-flex;
  padding: 2px 8px;
  gap: 6px;
  align-items: center;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  /* background: #f9fafb; */
  mix-blend-mode: multiply;
  /* color: #344054; */
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
span.mdRndTag {
  display: inline-flex;
  padding: 2px 10px;
  gap: 6px;
  align-items: center;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  /* background: #f9fafb; */
  mix-blend-mode: multiply;

  /* color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
span.ldRndTag {
  display: inline-flex;
  padding: 4px 12px;
  gap: 6px;
  align-items: center;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  /* background: #f9fafb; */
  mix-blend-mode: multiply;
  /* color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
span.ldRndDotTag {
  display: inline-flex;
  padding: 4px 12px;
  padding-inline-start: 0px;
  align-items: center;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  /* background: #f9fafb; */
  mix-blend-mode: multiply;
  /* color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

span.ldRndDotTag::before {
  content: "\00B7";
  font-size: 70px;
  width: 6px;
  height: 6px;
  margin-right: 20px;
  margin-bottom: 6px;
  font-family: "Supreme";
}
span.mdRndDotTag {
  display: inline-flex;
  padding: 2px 10px;
  padding-inline-start: 0px;
  align-items: center;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  /* background: #f9fafb; */
  mix-blend-mode: multiply;

  /* color: #344054; */
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
span.mdRndDotTag::before {
  content: "\00B7";
  font-size: 70px;
  width: 6px;
  height: 6px;
  margin-right: 20px;
  margin-bottom: 6px;
  font-family: "Supreme"; /* Unicode character for a middle dot */
  /* Adjust the spacing between the dot and the text */
}
span.smRndDotTag {
  display: inline-flex;
  padding: 2px 8px;
  padding-inline-start: 0px;
  align-items: center;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  /* background: #f9fafb; */
  mix-blend-mode: multiply;
  /* color: #344054; */
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
span.smRndDotTag::before {
  content: "\00B7";
  font-size: 70px;
  width: 6px;
  height: 6px;
  margin-right: 20px;
  margin-bottom: 6px;
  font-family: "Supreme"; /* Unicode character for a middle dot */
  /* Adjust the spacing between the dot and the text */
}
