/* Styles for the Collapse component */
.ia .ant-collapse {
  border: 1px solid #d9d9d9; /* Default border color for Collapse */
  border-radius: 2px;
  margin-bottom: 16px;
}

/* Styles for the Collapse header */
.ia .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffff;
  padding: 12px 16px;
  font-weight: bold;
}

/* Styles for the active Collapse header */
.ia .ant-collapse-item-active > .ant-collapse-header {
  border-color: #d9d9d9;
}

/* Styles for the Collapse content */
.ia .ant-collapse-content {
  border-top: 1px solid #d9d9d9;
}

/* Styles for the Panel */
.ia .ant-collapse-content-box {
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}

.ia .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

/* Additional spacing between Panels */
.ia .ant-collapse > .ant-collapse-item + .ant-collapse-item {
  margin-top: 16px;
}
